import Navbar from './components/Navbar'
import React from 'react'
import Primary from './components/Primary'
import About from './components/About'
import Jobs from './components/Jobs'
import Contact from './components/Contact'
import Footer from './components/Footer'

function App() {

    return ( <>
                <Navbar / >
                <Primary / >
                <About / >
                <Jobs / >
                <Contact / >
                <Footer />
        </>
    );
}

export default App;