import React from 'react'
import './css/About.css'
export default function About() {
  return (
    <div className="about-container">
      <h1 className="about-title">אודותינו</h1>

      <div className="about-card">
          <span className="about-card-text">
          המשרד הוקם ע"י דוידוב יצחק בעל תואר ראשון במנהל עסקים התמחות חשבונאית ובעל ניסיון של יותר משני עשורים בכל נושאי הנהלת חשבונות, חשבות שכר וביקורת חשבונאית.
          </span>
      </div>
    </div>
  )
}
