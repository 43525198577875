import React from 'react'
import './css/Navbar.css'
export default function Navbar() {
  return (
    <div className="navbar">

        <div className="navbar-logo">{logoGenerator("ydcpa")}</div>
            <div className="navbar-menu">

            </div>
    </div>
  )
}

const logoGenerator = (text) => {
    if(typeof text !== 'string') return;
    const textArray = text.split('');
    return textArray.map((letter, index) => {
        return <span key={index} className="logo-letter">{letter}</span>
    })
}