import React from 'react'
import './css/Footer.css'

export default function Footer() {
  return (
    <div className='footer-container'>
      <span>האתר נבנה על ידי </span>
      <a href='https://github.com/avishaidv'>אבישי דוידוב</a>
    </div>
  )
}
