import React from 'react'
import './css/Primary.css'
import TypeWriterEffect from 'react-typewriter-effect';

export default function Primary() {
  return (
    <div className="primary-container">
        <h1 className="primary-title">דוידוב יצחק</h1>
        <TypeWriterEffect 
        textStyle={{
                    fontFamily: 'Open Sans',
                    color: 'white',
                    fontWeight: 600,
                    fontSize: '1.5em',
                    direction: "rtl",
                    textAlign: "center"
                  }}
        cursorColor = "white"
        hideCursorAfterText = {true}
        multiText={
            [
                "משרד לייעוץ וניהול פיננסי מקצועי.",
                "משרד לייעוץ וניהול פיננסי שמדבר בגובה העיניים.",
                "משרד לייעוץ וניהול פיננסי שזמין כשצריך.",
                "משרד לייעוץ וניהול פיננסי שאיתך גם ברגעים הקשים.",
                "משרד לייעוץ וניהול פיננסי שפועל בשבילך.",
                "משרד לייעוץ וניהול פיננסי עם ניסיון של מעל שני עשורים.",
                "משרד לייעוץ וניהול פיננסי שאפשר לסמוך עליו.",
                "משרד לייעוץ וניהול פיננסי מקצועי."
            ]
        }/>
    </div>
  )
}
