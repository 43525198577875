import React from 'react'
import './css/Jobs.css'

export default function Jobs() {
  return (
        <div className="jobs-container">
            <Card 
            name="הנהלת חשבונות"
            content="למשרדינו ניסיון עשיר בכל סוגי הנהלת חשבונות –  לחברות, עצמאים ועמותות בכל הגדלים וסוגים."
            image="tax.jpg"
            />
            <Card 
            name="ניהול עסקים"
            content="למשרדינו ניסיון של יותר מ־15 שנים בהקמת חברות מ־א–ת, כולל פתיחת חברות ועסקים ברשויות וניהול שוטף."
            image="calculator2.jpg"
            />
            <Card
            name="חשבות וביקורת"
            content="משרדינו עוסק בכל סוגי הנהלת חשבונות –  לחברות, עצמאים ועמותות בכל הגדלים משרדינו נותן שרותי חשבות שכר וביקורת חברות בכל הגדלים, הכנת מאזנים, הגשה לרשויות וייצוג לקוחות מול הרשויות."
            image="money.jpg"
            />

        </div>
  )
}

function Card({name, content, image}) {
  return (
    <div className='card-container'>
            <img className='card-image'src={"/images/" + image} alt={name} draggable={false}/>
        <div className='card-content'>
            <h1 className='card-title'>{name}</h1>
            <p className='card-text'>{content}</p>
        </div>
    </div>
  )
}
