import React from 'react'
import './css/Contact.css'

export default function Contact() {
  return (
    <div className="contact-container">
      <div className="contact-card">  
        <h1>ליצירת קשר:</h1>
        <a href="mailto:yitzhak@ydcpa.co.il">yitzhak@ydcpa.co.il</a>
        <a href="tel:036820770">03-682-0770</a>
        <img src="/images/nofacebook.jpg" alt="nofacebook" draggable={false}/>
        <span>אל תחפשו אותנו, פשוט תצרו קשר.</span>
      </div>
    </ div>
  )
}
